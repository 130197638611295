<template>
  <v-container class="px-3" style="height: 100vh; background-color: white" fluid>
    <p class="pt-3" style="color: #292867; font-weight: bolder">
      <v-icon color="primary" class="pl-7 pr-2">fas fa-comment</v-icon
      > Atendimentos</p>
    <v-card style="height: 91vh">
      <v-row class="pt-2 justify-center">
        <v-col cols="10" class="pb-0 mb-0">
          <v-form id="ticketForm" ref="ticketForm" @submit.prevent="createTicket">
            <v-layout row wrap>
              <v-row>
                <v-col>
                  <h2 class="generic-title">{{ caseData.subject }}</h2>
                  <v-flex>
                    <v-select
                      label="Benefício / Operadora"
                      :items="contracts"
                      item-text="text"
                      v-model="caseData.contract"
                      placeholder="Selecione um benefício"
                      :rules="[rules.required]"
                    />
                  </v-flex>
                  <v-flex xs12>
                    <v-text-field
                      label="Solicitação"
                      v-model="caseData.description"
                      placeholder="Descreva sua Solicitação"
                      :rules="[rules.required]"
                    ></v-text-field>
                  </v-flex>
                  <v-flex>
                    <form-file-upload
                      :outline="true"
                      ref="customFileUpload"
                      :maximum="10"
                      :limit-file-size="12"
                      @update="setAttachments"
                    />
                  </v-flex>
                </v-col>
                </v-row>
              </v-layout>
          </v-form>
        </v-col>
        <v-row justify="center" class="btn-position px-2">
          <v-col cols="11">
            <GenericBtn
              :loading="busy"
              :disabled="busy"
              class="mt-2"
              :btn-props="{
                block: true,
                large: true,
                color:'primary',
                type:'submit',
                form:'ticketForm',
              }"
            >Solicitar
            </GenericBtn>
            <GenericBtn
                color="secondary"
                class="mt-5"
                :btn-props="{
              block: true,
              large: true,
              type:'submit',
              form:'ticketForm',
            }"
                :on-click="() => ($router.back())"
            >Cancelar
            </GenericBtn>
          </v-col>
        </v-row>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>

import { mapMutations, mapGetters, mapState } from "vuex";
import { formrules } from "@/common/formrules";
import FormFileUpload from "../../components/generic-upload/FormFileUpload";
import $auth from "../../common/auth";
import CaseService from "../../services/salesforce/CaseService";
import ComentsService from "@/services/salesforce/ComentsService";
import GenericBtn from "../../components/generic-btn/index"

export default {
  name: 'AtendimentoInserir',
  components: {
    FormFileUpload,
    GenericBtn
  },
  data: () => ({
    defaultErrorObject: { text: "Erro ao inserir", type: "error" },
    caseData: {
      account: null,
      type: null,
      subject: null,
      description: '',
      attachments: [],
      contract : null
    },
    contracts: [],
    rules: formrules,
    busy: false,
    dialog: false,
  }),
  computed: {
    ...mapState(["window"]),
    ...mapGetters(['isMobileSize']),
  },
  methods: {
    ...mapMutations(['showmsg', 'loading']),
    setAttachments(attachments) {
      this.caseData.attachments = attachments;
    },
    async createTicket() {

      if (!this.$refs.ticketForm.validate()) {
        setTimeout(this.$refs.ticketForm.resetValidation, 3000);
        return;
      }

      this.loading(true);
      const configuredCaseData = {
        contract__c : this.caseData.contract,
        beneficiary_id__c: this.contracts.find(
            (contract) => contract.value === this.caseData.contract
        ).beneficiaryID,
        accountId: this.contracts.find(
                (contract) => contract.value === this.caseData.contract
        ).accountID,
        subject: this.caseData.subject,
        type: this.caseData.type,
        origin: "eu_protegido",
        description: this.caseData.description,
        suppliedName: $auth.user().name,
        suppliedEmail: $auth.user().email,
      };

      let emptyFile = false;
      let emptyFileNames = "";
      const attachments = this.caseData.attachments.map((file) => {
        if (!(file.body.split(",")[1])) {
          emptyFile = true;
          emptyFileNames+= file.name + "<br/>"
        }

        let fileName =
            file.name.substring(0, file.name.lastIndexOf(".")) || "";
        let fileExtension =
            file.name.substring(file.name.lastIndexOf("."), file.length) || "";

        let f = {
          PathOnClient: fileExtension,
          Title: fileName,
          VersionData: file.body.split(",")[1],
          Description: "CLIENT",
        };

        return f;
      });

      if(emptyFile){
        this.showmsg({ text: 'Você selecionou arquivo(s) vazio(s) (0KB).<br/>Remova-o(s) antes de prosseguir.<br/><br/><b>' + emptyFileNames + '<b>', type: "error" });
        this.loading(false);
        return;
      }

      let caseEvent = {
        caseObject: configuredCaseData,
        files: attachments,
      };

      await this._caseService
          .CreateCaseWithAttachment(caseEvent)
          .then((response) => {
            this.$router.push(`/atendimento/${response.data}`);
          })
          .catch(() => this.showmsg(this.defaultErrorObject))
          .finally(() => this.loading(false));

      await this._
    },
    async loadBenefits(){
      const result = $auth.user()
          .beneficiaries.map((beneficiary) =>{
        return {
          beneficiaryID: beneficiary.beneficiaryId,
          accountID: beneficiary.accountId,
          value: beneficiary.contractId,
          text: `${beneficiary.benefitName} / ${beneficiary.carrierName}`
        }
      });
      this.contracts.push(...result);
    }
  },
  async created(){
    this._caseService = new CaseService();
    this._comentsService = new ComentsService();
    await this.loadBenefits();
  },
  beforeMount() {
    if(this.$route.params) {
      this.caseData.subject = this.$route.params.optionTitle;
      this.caseData.type = this.$route.params.optionOptions;
    }
  },
};
</script>
<style scoped>

@media(min-height: 569px) {
  .btn-position {
    margin-top: -70px
  }
}

@media(min-height: 639px) {
  .btn-position {
    margin-top: 30px
  }
}

@media(min-height: 730px) {
  .btn-position {
    margin-top: 8vh
  }
}

@media(min-height: 822px) {
  .btn-position {
    margin-top: 20vh
  }
}

@media(min-height: 661px) {
  .btn-position {
    margin-top: 15vh
  }
}

@media(min-height: 735px) {
  .btn-position {
    margin-top: 20vh
  }
}

@media(min-height: 800px) {
  .btn-position {
    margin-top: 28vh
  }
}

</style>
