var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"px-3",staticStyle:{"height":"100vh","background-color":"white"},attrs:{"fluid":""}},[_c('p',{staticClass:"pt-3",staticStyle:{"color":"#292867","font-weight":"bolder"}},[_c('v-icon',{staticClass:"pl-7 pr-2",attrs:{"color":"primary"}},[_vm._v("fas fa-comment")]),_vm._v(" Atendimentos")],1),_c('v-card',{staticStyle:{"height":"91vh"}},[_c('v-row',{staticClass:"pt-2 justify-center"},[_c('v-col',{staticClass:"pb-0 mb-0",attrs:{"cols":"10"}},[_c('v-form',{ref:"ticketForm",attrs:{"id":"ticketForm"},on:{"submit":function($event){$event.preventDefault();return _vm.createTicket.apply(null, arguments)}}},[_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-row',[_c('v-col',[_c('h2',{staticClass:"generic-title"},[_vm._v(_vm._s(_vm.caseData.subject))]),_c('v-flex',[_c('v-select',{attrs:{"label":"Benefício / Operadora","items":_vm.contracts,"item-text":"text","placeholder":"Selecione um benefício","rules":[_vm.rules.required]},model:{value:(_vm.caseData.contract),callback:function ($$v) {_vm.$set(_vm.caseData, "contract", $$v)},expression:"caseData.contract"}})],1),_c('v-flex',{attrs:{"xs12":""}},[_c('v-text-field',{attrs:{"label":"Solicitação","placeholder":"Descreva sua Solicitação","rules":[_vm.rules.required]},model:{value:(_vm.caseData.description),callback:function ($$v) {_vm.$set(_vm.caseData, "description", $$v)},expression:"caseData.description"}})],1),_c('v-flex',[_c('form-file-upload',{ref:"customFileUpload",attrs:{"outline":true,"maximum":10,"limit-file-size":12},on:{"update":_vm.setAttachments}})],1)],1)],1)],1)],1)],1),_c('v-row',{staticClass:"btn-position px-2",attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"11"}},[_c('GenericBtn',{staticClass:"mt-2",attrs:{"loading":_vm.busy,"disabled":_vm.busy,"btn-props":{
              block: true,
              large: true,
              color:'primary',
              type:'submit',
              form:'ticketForm',
            }}},[_vm._v("Solicitar ")]),_c('GenericBtn',{staticClass:"mt-5",attrs:{"color":"secondary","btn-props":{
            block: true,
            large: true,
            type:'submit',
            form:'ticketForm',
          },"on-click":function () { return (_vm.$router.back()); }}},[_vm._v("Cancelar ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }